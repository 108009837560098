<!--
 * @Author: Dyf
 * @Date: 2023-11-07 14:37:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-29 15:19:31
 * @Description: 荣誉证书
-->
<style lang="scss" scoped>
.honor-certificate {
  @include innerPage($p: 32px 0 0);
  @include pageTitle(0);
  @include pageFoot;
  .honor-inner {
    width: 100%;
    height: calc(100% - 90px);
    margin-top: 20px;
    .honor-list-box {
      width: 100%;
      height: calc(100% - 98px);
      margin-bottom: 24px;
      position: relative;
      .honor-list {
        width: calc(100% + 10px);
        height: 100%;
        box-sizing: border-box;
        padding-right: 10px;
        overflow: hidden;
        overflow-y: auto;
        flex-wrap: wrap;
        @include flexBox(flex-start, flex-start);

        .honor-item {
          width: calc((100% - 80px) / 4);
          height: 394px;
          border-radius: 10px;
          background: #fff;
          margin-right: 20px;
          margin-bottom: 16px;
          cursor: pointer;
          .el-image {
            width: 100%;
            height: 284px;
            border-radius: 10px;
          }
          h5 {
            box-sizing: border-box;
            padding: 0 28px;
            line-height: 24px;
            margin-top: 14px;
          }
          .contorl-foot {
            height: 58px;
            border-top: 1px solid rgba($color: #585858, $alpha: 0.14);
            margin-top: 10px;
            box-sizing: border-box;
            padding: 0 28px;
            @include flexBox(flex-end);
            .el-button.is-circle {
              min-width: 32px;
              margin-right: auto;
              i {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  .dialog-container {
    width: 100%;
    max-height: 68vh;
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
    box-sizing: border-box;
    padding: 10px 20px 0;
    .temps-inner {
      width: calc(100% + 10px);
      min-height: 348px;
      max-height: calc(68vh - 74px);
      overflow: hidden;
      overflow-y: auto;
      margin: 8px 0;
      box-sizing: border-box;
      padding-right: 10px;
    }
    .temps-list {
      flex-wrap: wrap;
      @include flexBox(space-between, flex-start);
      li {
        width: calc((100% - 24px) / 3);
        height: 200px;
        min-width: 240px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        border-radius: 4px;
        .el-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .el-radio {
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 2;
          ::v-deep {
            .el-radio__label {
              visibility: hidden;
            }
            .el-radio__input.is-checked .el-radio__inner::before {
              transform: scale(1);
            }
            .el-radio__inner {
              text-align: center;
              line-height: 18px;
              &::before {
                content: "\e63a";
                font-family: "iconfont";
                font-size: 12px;
                color: #fff;
                transform: scale(0);
                display: inline-block;
                @include defaultAni;
              }
            }
          }
        }
        .el-tag {
          position: absolute;
          bottom: 10px;
          right: 10px;
          z-index: 2;
          border-color: #1fccb6;
          background: rgba($color: #2ac293, $alpha: 1);
          color: #fff;
        }
      }
      &::after {
        content: "";
        width: calc((100% - 24px) / 3);
        min-width: 240px;
      }
    }
    .custom-foot {
      height: 48px;
      padding-top: 12px;
      border-top-color: transparent;
      ::v-deep {
        .el-pagination.is-background .el-pager {
          height: 32px;
        }
        .el-pagination.is-background .el-pager .number {
          width: 32px;
          height: 32px;
          border-radius: 6px;
          line-height: 32px;
        }
        .page-jump .jump-ipt input {
          height: 32px;
        }
        .el-button--medium {
          min-width: 48px;
          height: 32px;
        }
      }
    }
  }
  .dialog-container--form {
    height: 426px;
    box-sizing: border-box;
    padding: 46px 0 42px 0;

    .el-form-item:last-child {
      margin-bottom: 0;
    }

    ::v-deep .el-textarea__inner {
      min-height: 110px !important;
    }
    .img-uploader {
      min-width: 84px;
      min-height: 84px;

      ::v-deep .el-upload--text {
        width: 84px;
        height: 84px;
        @include flexBox;
      }
      .img-box {
        height: 84px;
        margin-right: 20px;
        position: relative;

        .el-image {
          border-radius: 10px;
        }

        .del {
          width: 24px;
          height: 24px;
          background: #fff;
          position: absolute;
          top: -4px;
          right: -12px;
          font-size: 32px;
          border-radius: 50%;
          color: #f8516d;
          @include flexBox(center);
        }
      }

      .upload-wrapper {
        width: 84px;
        height: 84px;
        background: #2ac293;
        border-radius: 10px;
        color: #fff;
        font-size: 50px;
        flex-shrink: 0;
        @include flexBox(center);
      }
    }
    .temp-info {
      height: 46px;
      @include flexBox;
      span {
        display: inline-block;
        overflow: hidden;
        margin-right: auto;
        box-sizing: border-box;
        padding-right: 10px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .honor-certificate {
    @include innerPage($p: 18px 0 0);
    .honor-inner {
      height: calc(100% - 52px);
      .honor-list-box {
        height: calc(100% - 68px);
        margin-bottom: 16px;
        .honor-list .honor-item {
          width: calc((100% - 42px) / 3);
          height: 298px;
          margin-right: 14px;
          margin-bottom: 8px;
          .el-image {
            height: 194px;
          }
          h5 {
            margin-top: 8px;
          }
          .contorl-foot {
            padding: 0 16px;
          }
        }
      }
    }
    ::v-deep {
      .el-dialog {
        min-width: 564px !important;
      }
    }
    .dialog-container {
      .temps-list li {
        height: 170px;
      }
    }
    .dialog-container--form {
      padding: 24px;
    }
  }
}
</style>
<template>
  <section class="honor-certificate">
    <div class="page-title">
      <h3>荣誉证书</h3>
      <el-button type="custom_primary" size="small" @click="showForm"
        >上传证书</el-button
      >
    </div>
    <div class="honor-inner">
      <div
        class="honor-list-box"
        :class="{ 'no-data': $isEmpty(listData) }"
        v-loading="dataLoad"
      >
        <ul class="honor-list" v-if="!$isEmpty(listData)">
          <li
            class="honor-item"
            v-for="item in listData"
            :key="item.stuser_cer_id"
          >
            <el-image
              fit="cover"
              :src="formatfile(item.suser_cer_synthesisurl)"
              :preview-src-list="[formatfile(item.suser_cer_synthesisurl)]"
            />
            <h5 class="bold line-text--1st">{{ item.suser_cer_title }}</h5>
            <div class="contorl-foot">
              <el-button
                type="custom_danger"
                size="mini"
                circle
                @click="delCer(item.stuser_cer_id)"
                v-loading="btnLoad[0]"
              >
                <i class="iconfont">&#xe620;</i>
              </el-button>
              <el-button
                type="custom_warning"
                size="mini"
                @click="showTemp(item.stuser_cer_id)"
                >更换模板</el-button
              >
              <el-button
                type="custom_success"
                size="mini"
                @click="downloadFile(item.suser_cer_synthesisurl)"
                v-loading="btnLoad[1]"
                >下载</el-button
              >
            </div>
          </li>
        </ul>
        <div class="no-data--empty" v-if="$isEmpty(listData) && !dataLoad">
          <img src="@assets/images/no-data3.png" alt="" />
          <p>暂无证书数据哦~</p>
        </div>
      </div>
      <!-- 分页控件 -->
      <div class="custom-foot">
        <customPagination
          :current="listPage.pageIndex"
          :total="listPage.total"
          :size="listPage.pageSize"
          @pageChange="flippingPage"
        />
      </div>
    </div>
    <el-dialog
      title="上传证书"
      width="460px"
      :visible.sync="formDialog"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="dialog-container--form">
        <el-form
          ref="formData"
          :model="formData"
          label-width="84px"
          :rules="rules"
        >
          <el-form-item label="证书名称：" prop="suser_cer_title">
            <el-input
              v-model.trim="formData.suser_cer_title"
              maxlength="20"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="证书模板：" prop="plcer_tem_id">
            <p class="temp-info">
              <span class="line-text--1st" v-if="curtem.plcer_tem_title">{{
                curtem.plcer_tem_title
              }}</span>
              <el-button type="custom_success" size="mini" @click="showTemp()"
                >选择模板</el-button
              >
            </p>
          </el-form-item>
          <el-form-item label="上传证书：" prop="suser_cer_url">
            <el-upload
              class="img-uploader"
              ref="imgUploader"
              :action="$upload.imgAction"
              :headers="$upload.header"
              :show-file-list="false"
              :accept="$upload.imgAccept"
              :before-upload="$beforImgUpload"
              :on-success="
                (res) => {
                  return uploadImgSuccess(res);
                }
              "
            >
              <div class="img-box" v-if="formData.suser_cer_url">
                <el-image
                  style="width: 84px; height: 84px"
                  :src="formatfile(formData.suser_cer_url)"
                  :preview-src-list="[formatfile(formData.suser_cer_url)]"
                />
                <i class="del iconfont" @click.stop="delImg">&#xe63e;</i>
              </div>
              <div class="upload-wrapper">
                <i class="iconfont">&#xe63d;</i>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="证书预览：">
            <el-image
              style="width: 84px; height: 84px"
              :src="formatfile(formData.suser_cer_synthesisurl)"
              :preview-src-list="[formatfile(formData.suser_cer_synthesisurl)]"
            >
              <div slot="error" class="image-slot">
                <img
                  style="width: 84px; height: 84px; object-fit: cover"
                  src="@assets/images/empty_img.png"
                  alt=""
                />
              </div>
            </el-image>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="custom_info" size="small" @click="hideForm"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="handleSubmit"
          v-loading="subload"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="证书模板" :visible.sync="tempDialog" @close="hideTemp">
      <div class="dialog-container">
        <div
          class="temps-inner"
          :class="{ 'no-data': $isEmpty(tempsData) }"
          v-loading="tempsLoad"
        >
          <el-radio-group
            v-show="!$isEmpty(tempsData)"
            v-model="tempIndex"
            @input="checkTemp"
          >
            <ul class="temps-list">
              <li
                v-for="(item, index) in tempsData"
                :key="item.plcer_tem_id"
                @click="checkTemp(index)"
              >
                <el-radio :label="index"></el-radio>
                <el-image fit="cover" :src="formatfile(item.plcer_tem_image)" />
                <el-tag type="success">{{
                  (item.plcer_tem_is_cost == 1 && "已付费") || "免费"
                }}</el-tag>
              </li>
            </ul>
          </el-radio-group>
          <div class="no-data--empty" v-if="$isEmpty(tempsData) && !tempsLoad">
            <img src="@assets/images/no-data3.png" alt="" />
            <p>暂无证书模板哦~</p>
          </div>
        </div>
        <!-- 分页控件 -->
        <div class="custom-foot">
          <customPagination
            :current="tempsPage.pageIndex"
            :total="tempsPage.total"
            :size="tempsPage.pageSize"
            @pageChange="flippingPage"
          />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="custom_info"
          size="small"
          @click="
            cancleChoose();
            hideTemp();
          "
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="draw"
          v-loading="useload"
          >使用该模板</el-button
        >
      </div>
    </el-dialog>
    <div style="width: 0; height: 0; overflow: hidden">
      <img
        id="temp_url"
        :src="formatfile(temp_url)"
        style="display: block"
        crossorigin="anonymous"
      />
      <img
        id="cer_url"
        :src="formatfile(cer_url)"
        style="display: block"
        crossorigin="anonymous"
      />
    </div>
  </section>
</template>

<script>
import {
  stuusercertificateList,
  stuusercertificateInfo,
  placertificatetemplateList,
  stuusercertificateAdd,
  stuusercertificateDelete,
  stuusercertificateEdit,
} from "@api/center";
import { $downloadFile, $uploadImg } from "@api/common";
import customPagination from "@comp/customPagination";
import { formatFile } from "@utils";
export default {
  components: { customPagination },
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      temp_url: "", // 模板图片
      cer_url: "", // 证书图片
      tempDialog: false, // 模板弹窗
      tempIndex: null, // 模板下标
      certificateDetail: {}, // 证书详情
      curtem: {}, // 当前模板信息
      formDialog: false, // 上传表单弹窗
      formData: {}, // 上传表单
      listData: [], // 证书列表数据
      /* 表格分页参数 */
      listPage: {
        pageIndex: 1,
        pageSize: 12,
        total: 0,
      },
      tempsData: [], // 模板列表数据
      /* 表格分页参数 */
      tempsPage: {
        pageIndex: 1,
        pageSize: 12,
        total: 0,
      },
      rules: {
        suser_cer_title: [
          { required: true, message: "请填写证书名称", trigger: "blur" },
        ],
        plcer_tem_id: [
          { required: true, message: "请选择证书模板", trigger: "change" },
        ],
        suser_cer_url: [
          { required: true, message: "请上传证书图片", trigger: "change" },
        ],
      },
      dataLoad: false, // 列表数据加载
      tempsLoad: false, // 模板数据加载
      btnLoad: [false, false],
      useload: false, // 使用模板按钮加载
      subload: false, // 表单提交按钮加载
    };
  },
  created() {
    this.getCertificate();
  },
  methods: {
    /** 获取证书列表 */
    async getCertificate() {
      this.dataLoad = true;
      let { data: res } = await stuusercertificateList({
        pageindex: this.listPage.pageIndex,
        pagesize: this.listPage.pageSize,
      });
      this.dataLoad = false;
      this.listData = res.data;
      this.listPage.total = res.allcount;
    },
    /** 获取证书详情 */
    async getDetail(stuser_cer_id) {
      let { data } = await stuusercertificateInfo({ stuser_cer_id });
      this.certificateDetail = data;
      this.temp_url = data.plcer_tem_image;
      this.cer_url = data.suser_cer_url;
    },
    /** 获取证书模板列表 */
    async getTemps() {
      this.tempsLoad = true;
      let { data: res } = await placertificatetemplateList({
        pageindex: this.tempsPage.pageIndex,
        pagesize: this.tempsPage.pageSize,
      });
      this.tempsLoad = false;
      res.data.map((item, index) => {
        if (item.plcer_tem_id == this.certificateDetail.plcer_tem_id) {
          this.tempIndex = index;
        }
      });
      this.tempsData = res.data;
      this.tempsPage.total = res.allcount;
    },
    /** 翻页 */
    flippingPage(val) {
      this.listData = [];
      this.listPage.pageIndex = val;
      this.getData();
    },
    /** 证书下载 */
    async downloadFile(url) {
      this.btnLoad[1] = true;
      let { data } = await $downloadFile(url);
      this.btnLoad[1] = false;
      if (data.url) {
        this.$fileDownload(data.url);
      }
    },
    /** 绘制模板、证书拼合图片 */
    async draw() {
      if (this.formDialog) {
        this.formData = {
          ...this.formData,
          plcer_tem_id: this.curtem.plcer_tem_id,
          plcer_tem_image: this.curtem.plcer_tem_image,
        };
        this.$nextTick(() => {
          if(this.curtem.plcer_tem_id) this.$refs.formData.validateField("plcer_tem_id");
        });
      }
      if (!this.cer_url || !this.temp_url) {
        return this.hideTemp();
      }
      if (this.cer_url && this.temp_url) {
        const temp_url = document.getElementById("temp_url"); // 模板图片
        const cer_url = document.getElementById("cer_url"); // 证书图片
        const w = temp_url.clientWidth; // 模板图片宽
        const h = temp_url.clientHeight; // 模板图片高
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = w;
        canvas.height = h;
        // 将证书图片加入画布
        context.drawImage(cer_url, 0, 0, w, h);
        // 将模板图片加入画布
        context.drawImage(temp_url, 0, 0, w, h);
        // 将画布内容导出
        let base64Img = canvas.toDataURL("image/png");
        let imgFile = this.dataURLtoFile(base64Img);
        /* 拼合图片上传 */
        let { data } = await $uploadImg(imgFile);
        if (this.certificateDetail.stuser_cer_id) {
          this.changeTemp(data.address);
        } else {
          this.formData.suser_cer_synthesisurl = data.address;
          this.hideTemp();
        }
      }
    },
    handleSubmit() {
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          this.subload = true;
          let res = await stuusercertificateAdd(this.formData);
          this.subload = false;
          if (res) {
            this.$message.success("上传成功");
            this.hideForm();
            this.getCertificate();
          }
        }
      });
    },
    /** 请求更换模板 */
    async changeTemp(suser_cer_synthesisurl) {
      this.useload = true;
      let params = {
        stuser_cer_id: this.certificateDetail.stuser_cer_id,
        plcer_tem_id: this.tempsData[this.tempIndex].plcer_tem_id,
        plcer_tem_image: this.temp_url,
        suser_cer_url: this.cer_url,
        suser_cer_synthesisurl,
      };
      let res = await stuusercertificateEdit(params);
      this.useload = false;
      if (res.status == 200) {
        this.$message.success("模板更换成功");
        this.getCertificate();
        this.hideTemp();
      }
    },
    /** 删除证书 */
    async delCer(stuser_cer_id) {
      this.$confirm("确定删除此证书？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(async () => {
          this.btnLoad[0] = true;
          let res = await stuusercertificateDelete({ stuser_cer_id });
          this.btnLoad[0] = false;
          if (res) {
            this.$message.success("证书已删除");
            this.listPage.total =
              (this.listPage.total - 1 < 0 && 0) || this.listPage.total - 1;
            if (this.listData.length == 1 && this.listPage.pageIndex != 1) {
              this.listPage.pageIndex = this.listPage.pageIndex - 1;
            }
            this.getCertificate();
          }
        })
        .catch(() => {
          this.$message.info("操作已取消");
        });
    },
    /** 展开模板弹窗 */
    showForm() {
      this.formDialog = true;
      this.$nextTick(() => {
        this.$refs.formData.clearValidate();
      });
    },
    /** 关闭模板弹窗 */
    hideForm() {
      this.curtem = {};
      this.cancleChoose();
      this.hideTemp();
      this.$nextTick(() => {
        this.$refs.formData.resetFields();
        this.formData = {};
        this.formDialog = false;
        this.$forceUpdate();
      });
    },
    /** 展开模板弹窗 */
    showTemp(id = null) {
      if (id) {
        this.getDetail(id);
      }
      this.tempDialog = true;
      this.getTemps();
    },
    /** 关闭模板弹窗 */
    hideTemp() {
      this.tempsPage = {
        ...this.tempsPage,
        pageIndex: 1,
        total: 0,
      };
      this.tempsData = [];
      this.certificateDetail = {};
      this.tempDialog = false;
    },
    /** 取消证书模板选择 */
    cancleChoose() {
      this.tempIndex = null;
      this.temp_url = "";
      this.cer_url = "";
      if (this.formDialog) {
        this.curtem = {};
      }
    },
    /** 选择证书模板 */
    checkTemp(val) {
      this.tempIndex = val;
      this.temp_url = this.tempsData[this.tempIndex].plcer_tem_image;
      if (this.formDialog) {
        this.curtem = this.tempsData[this.tempIndex];
      }
    },
    /**
     * 转换base64编码图片转换为file类型图片文件
     * @param {string} dataurl base64编码图片
     * @param {string} filename 文件类型
     */
    dataURLtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    /**
     * 图片上传成功
     * @param {object} data 响应数据
     */
    uploadImgSuccess({ data }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.formData = {
        ...this.formData,
        suser_cer_url: data.address,
      };
      this.cer_url = data.address;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$refs.formData.validateField("suser_cer_url");
        setTimeout(() => {
          this.draw();
        }, 500);
      });
    },
    /** 删除图组图片 */
    delImg() {
      delete this.formData.suser_cer_url;
      delete this.formData.suser_cer_synthesisurl;
      this.cer_url = "";
      this.$refs.imgUploader.uploadFiles = [];
      this.$forceUpdate();
    },
  },
};
</script>